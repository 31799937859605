export default {
  setPackages: (state, payload) => {
    state.packages = payload;
  },
  setMySubs: (state, payload) => {
    state.mySubs = payload;
  },
  setFibToken(state, fibToken) {
    state.fibToken = fibToken;
  },
};
