export default {
  setWorkshops: (state, payload) => {
    state.workshops = payload;
  },
  setWorkshop: (state, payload) => {
    state.workshop = payload;
  },
  setTickets: (state, payload) => {
    state.tickets = payload;
  },
  setGuestTicket: (state, payload) => {
    state.guestTicket = payload;
  },
  setFibPaymentData(state, data) {
    state.fibPaymentData = data;
  },
  setShowFibModal(state, value) {
    state.showFibModal = value;
  },
};
