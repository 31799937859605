export default {
  setRequests: (state, payload) => {
    state.requests = payload;
  },
  setRequest: (state, payload) => {
    state.request = payload;
  },
  setFibToken(state, fibToken) {
    state.fibToken = fibToken;
  },
};
