import { http } from "../../../graphql";
import gql from "graphql-tag";
import router from "../../../router";

export default {
  orders: async (context) => {
    // let page = payload?.page ?? 1
    // let first = payload?.first ?? 6

    let query = gql`
      # query orders($page: Int!, $first: Int!) {
      #   orders (page: $page, first: $first){
      query orders {
        orders {
          # paginatorInfo {
          #   count
          #   currentPage
          #   firstItem
          #   hasMorePages
          #   lastItem
          #   lastPage
          #   perPage
          #   total
          # }
          # data{
          id
          total
          paid
          date
          status
          sellingPoint {
            id
            title
            city {
              id
              name
            }
          }
          address
          city {
            id
            name
            price
          }
          products {
            id
            quantity
            color {
              id
              title
            }
            scent {
              id
              title
              image
            }
            size {
              id
              size {
                id
                title
              }
              price
            }
            detail {
              title
              image
              description
              price
              product {
                id
                rating
                ratings
                collection {
                  id
                  title
                }
              }
            }
          }
          refills {
            id
            total
            quantity
            wick {
              id
              title
              image
            }
            container {
              id
              title
            }
            color {
              id
              title
              color
              refill
            }
            size {
              id
              title
            }
            scent {
              id
              title
              image
            }
          }
          # }
        }
      }
    `;
    // const response = await http(true, query, true, { page: page, first: first })
    const response = await http(true, query, true);
    context.commit("setOrders", response.orders);
  },
  order: async (context, payload) => {
    let query = gql`
      query order($id: ID!) {
        order(id: $id) {
          id
          total
          paid
          pickup
          address
          city {
            id
            name
            price
          }
          status
          products {
            id
            quantity
            color {
              id
              title
            }
            scent {
              id
              title
              image
            }
            size {
              id
              size {
                id
                title
              }
              price
            }
            productDetail {
              product {
                id
                rating
              }
            }
          }
          refills {
            id
            total
            quantity
            wick {
              id
              title
              image
            }
            container {
              id
              title
            }
            color {
              id
              title
              color
              refill
            }
            size {
              id
              title
            }
            scent {
              id
              title
              image
            }
          }
        }
      }
    `;
    const response = await http(true, query, true, { id: payload });
    context.commit("setOrder", response.order);
  },
  guestOrder: async (context, payload) => {
    let query = gql`
      query guestOrder($uuid: String!, $email: String!) {
        guestOrder(uuid: $uuid, email: $email) {
          id
          total
          paid
          address
          is_guest
          date
          notes
          guest {
            id
            uuid
            name
            email
            phone
            address
          }
          city {
            id
            name
            price
          }
          status
          products {
            id
            quantity
            color {
              id
              title
            }
            scent {
              id
              title
              image
            }
            size {
              id
              size {
                id
                title
              }
              price
            }
            detail {
              title
              image
              description
              price
              product {
                id
                rating
                ratings
                collection {
                  id
                  title
                }
              }
            }
          }
          refills {
            id
            total
            quantity
            wick {
              id
              title
              image
            }
            container {
              id
              title
            }
            color {
              id
              title
              color
              refill
            }
            size {
              id
              title
            }
            scent {
              id
              title
              image
            }
          }
        }
      }
    `;
    const response = await http(true, query, true, {
      uuid: payload.uuid,
      email: payload.email,
    });
    context.commit("setGuestOrder", response.guestOrder);
  },
  product: async (context, payload) => {
    context.commit("setProduct", payload.product);
  },
  buyNow: async (context, payload) => {
    let mutation = gql`
      mutation buyNow($input: BuyNowInput!) {
        order: buyNow(input: $input) {
          id
          token
          fibToken {
            paymentId
            readableCode
            qrCode
            validUntil
            personalAppLink
            businessAppLink
            corporateAppLink
          }
        }
      }
    `;

    let input = {
      id: payload.id,
      quantity: parseInt(payload.quantity),
      method: payload.method ?? "cash",
    };

    if (payload.notes) input.notes = payload.notes;
    if (payload.address) input.address = payload.address;
    if (payload.city) input.city = payload.city;
    if (payload.size) input.size = payload.size;
    if (payload.scent) input.scent = payload.scent;
    if (payload.sellingPoint) input.selling_point = payload.sellingPoint;
    if (payload.color) input.color = payload.color;

    const response = await http(false, mutation, true, { input: input });
    context.commit("setNewOrder", response.order);

    if (payload.method === "zaincash") {
      window.location.replace(
        `https://api.zaincash.iq/transaction/pay?id=${response.order.token}`
      );
    } else if (payload.method == "fastpay") {
      window.location.replace(response.order.token);
    } else if (payload.method === "nasspay") {
      let json = JSON.parse(response.order.token);
      let transactionId = json.transactionId;
      let token = json.token;
      // let user = '7735313620'
      let user = "7517924925";

      // console.log(`https://checkout.nasswallet.com/payment-gateway?id=${transactionId}&token=${token}&userIdentifier=${user}`);
      window.location.replace(
        `https://checkout.nasswallet.com/payment-gateway?id=${transactionId}&token=${token}&userIdentifier=${user}`,
        "_blank"
      );
      // window.location.replace(`https://uatcheckout1.nasswallet.com/payment-gateway?id=${transactionId}&token=${token}&userIdentifier=${user}`, '_blank')
    } else if (payload.method === "fib") {
      // Store the fibToken in the state
      context.commit("setFibToken", response.order);

      router.push({ name: "fibPaymentDetails" });

      // window.location.replace(response.order.token);
    }
  },
  checkout: async (context, payload) => {
    let mutation = gql`
      mutation checkout($input: CheckoutInput!) {
        order: checkout(input: $input) {
          id
          token
          fibToken {
            paymentId
            readableCode
            qrCode
            validUntil
            personalAppLink
            businessAppLink
            corporateAppLink
          }
        }
      }
    `;
    let input = {
      method: payload.method ?? "cash",
    };

    if (payload.notes) input.notes = payload.notes;
    if (payload.address) input.address = payload.address;
    if (payload.city) input.city = payload.city;
    if (payload.sellingPoint) input.selling_point = payload.sellingPoint;

    const response = await http(false, mutation, true, { input: input });
    context.commit("setNewOrder", response.order);
    context.commit("cart/setCart", [], { root: true });

    // context.dispatch('user/user', null, { root: true })

    if (payload.method === "zaincash") {
      window.location.replace(
        `https://api.zaincash.iq/transaction/pay?id=${response.order.token}`
      );
    } else if (payload.method == "fastpay") {
      window.location.replace(response.order.token);
    } else if (payload.method === "nasspay") {
      let json = JSON.parse(response.order.token);
      let transactionId = json.transactionId;
      let token = json.token;
      // let user = '7735313620'
      let user = "7517924925";

      window.location.replace(
        `https://checkout.nasswallet.com/payment-gateway?id=${transactionId}&token=${token}&userIdentifier=${user}`,
        "_blank"
      );
      // window.location.replace(`https://uatcheckout1.nasswallet.com/payment-gateway?id=${transactionId}&token=${token}&userIdentifier=${user}`, '_blank')
    }
    if (payload.method === "fib") {
      // Store the fibToken in the state
      context.commit("setFibToken", response.order);

      router.push({ name: "fibPaymentDetails" });

      // window.location.replace(response.order.token);
    }
    // setTimeout(() => {
    // }, 2000);
  },
  buyNowGuest: async (context, payload) => {
    let mutation = gql`
      mutation buyNowGuest($input: BuyNowGuestInput!) {
        order: buyNowGuest(input: $input) {
          id
          token
          fibToken {
            paymentId
            readableCode
            qrCode
            validUntil
            personalAppLink
            businessAppLink
            corporateAppLink
          }
          uuid
          email
        }
      }
    `;

    let input = {
      id: payload.id,
      quantity: parseInt(payload.quantity),
      method: payload.method ?? "cash",
      token: payload.token,
      guest: {
        name: payload?.guest?.name,
        email: payload?.guest?.email,
        phone: payload?.guest?.phone,
        address: payload?.guest?.address,
      },
    };

    if (payload.notes) input.notes = payload.notes;
    if (payload.address) input.address = payload.address;
    if (payload.city) input.city = payload.city;
    if (payload.size) input.size = payload.size;
    if (payload.scent) input.scent = payload.scent;
    if (payload.sellingPoint) input.selling_point = payload.sellingPoint;
    if (payload.color) input.color = payload.color;

    const response = await http(false, mutation, true, { input: input });
    context.commit("setNewOrder", response.order);

    if (payload.method === "zaincash") {
      window.location.replace(
        `https://api.zaincash.iq/transaction/pay?id=${response.order.token}`
      );
    } else if (payload.method == "fastpay") {
      window.location.replace(response.order.token);
    } else if (payload.method === "nasspay") {
      let json = JSON.parse(response.order.token);
      let transactionId = json.transactionId;
      let token = json.token;
      // let user = '7735313620'
      let user = "7517924925";

      // console.log(`https://checkout.nasswallet.com/payment-gateway?id=${transactionId}&token=${token}&userIdentifier=${user}`);
      window.location.replace(
        `https://checkout.nasswallet.com/payment-gateway?id=${transactionId}&token=${token}&userIdentifier=${user}`,
        "_blank"
      );
      // window.location.replace(`https://uatcheckout1.nasswallet.com/payment-gateway?id=${transactionId}&token=${token}&userIdentifier=${user}`, '_blank')
    } else if (payload.method === "fib") {
      // Store the fibToken in the state
      context.commit("setFibToken", response.order);

      router.push({ name: "fibPaymentDetails" });
      // window.location.replace(response.order.token);
    } else if (payload.method === "cash") {
      router.push({
        name: "orderDetails",
        params: { id: response.order.uuid },
        query: { email: response.order.email },
      });
    }
  },
  guestCheckout: async (context, payload) => {
    let mutation = gql`
      mutation checkoutGuest($input: CheckoutGuestInput!) {
        order: checkoutGuest(input: $input) {
          id
          token
          fibToken {
            paymentId
            readableCode
            qrCode
            validUntil
            personalAppLink
            businessAppLink
            corporateAppLink
          }
          uuid
          email
        }
      }
    `;
    let input = {
      method: payload.method ?? "cash",
      token: payload.token,
      guest: {
        name: payload?.guest?.name,
        email: payload?.guest?.email,
        phone: payload?.guest?.phone,
        address: payload?.guest?.address,
      },
    };

    if (payload.notes) input.notes = payload.notes;
    if (payload.address) input.address = payload.address;
    if (payload.city) input.city = payload.city;
    if (payload.sellingPoint) input.selling_point = payload.sellingPoint;

    if (payload.products) {
      input.products = payload.products;
    }

    if (payload.refillItems) {
      input.refillItems = payload.refillItems;
    }

    const response = await http(false, mutation, true, { input: input });
    context.commit("setNewOrder", response.order);
    context.commit("cart/setCart", [], { root: true });

    // context.dispatch('user/user', null, { root: true })
    if (payload.method === "zaincash") {
      window.location.replace(
        `https://api.zaincash.iq/transaction/pay?id=${response.order.token}`
      );
    } else if (payload.method == "fastpay") {
      window.location.replace(response.order.token);
    } else if (payload.method === "nasspay") {
      let json = JSON.parse(response.order.token);
      let transactionId = json.transactionId;
      let token = json.token;
      // let user = '7735313620'
      let user = "7517924925";

      window.location.replace(
        `https://checkout.nasswallet.com/payment-gateway?id=${transactionId}&token=${token}&userIdentifier=${user}`,
        "_blank"
      );
      // window.location.replace(`https://uatcheckout1.nasswallet.com/payment-gateway?id=${transactionId}&token=${token}&userIdentifier=${user}`, '_blank')
    } else if (payload.method === "fib") {
      // Store the fibToken in the state
      context.commit("setFibToken", response.order);

      router.push({ name: "fibPaymentDetails" });
      // window.location.replace(response.order.token);
    } else if (payload.method === "cash") {
      router.push({
        name: "orderDetails",
        params: { id: response.order.uuid },
        query: { email: response.order.email },
      });
    }
    // setTimeout(() => {
    // }, 2000);
  },
};
