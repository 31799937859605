import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: () => ({
    refills: [],
    refill: null,
    cart: [],
    localCart: null,
    fibToken: null,
  }),
  actions,
  getters,
  mutations,
};
