export default {
  setRefills: (state, payload) => {
    state.refills = payload;
  },
  setRefill: (state, payload) => {
    state.refill = payload;
  },
  setCart: (state, payload) => {
    state.cart = payload;
  },
  setLocal: (state, payload) => {
    state.localCart = payload;
  },
  setFibToken(state, fibToken) {
    state.fibToken = fibToken;
  },
};
