export default {
  setOrders: (state, payload) => {
    state.orders = payload;
  },
  setOrder: (state, payload) => {
    state.order = payload;
  },
  setNewOrder: (state, payload) => {
    state.newOrder = payload;
  },
  setProduct: (state, payload) => {
    state.product = payload;
  },
  setGuestOrder: (state, payload) => {
    state.guestOrder = payload;
  },
  setFibToken(state, fibToken) {
    state.fibToken = fibToken;
  },
};
