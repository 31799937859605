import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: () => ({
    orders: [],
    order: null,
    newOrder: null,
    product: null,
    guestOrder: null,
    fibToken: null,
  }),
  actions,
  getters,
  mutations,
};
