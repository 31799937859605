import { http } from "../../../graphql";
import gql from "graphql-tag";
import router from "../../../router";

export default {
  pay: async (context, payload) => {
    let mutation = gql`
      mutation payLater($input: PayLaterInput!) {
        order: payLater(input: $input) {
          id
          token
          fibToken {
            paymentId
            readableCode
            qrCode
            validUntil
            personalAppLink
            businessAppLink
            corporateAppLink
          }
        }
      }
    `;

    let input = {
      id: payload.id,
      type: payload.type,
      method: payload.method ?? "nasspay",
    };

    const response = await http(false, mutation, true, { input: input });

    if (payload.method === "zaincash") {
      window.location.replace(
        `https://api.zaincash.iq/transaction/pay?id=${response.order.token}`
      );
    } else if (payload.method == "fastpay") {
      window.location.replace(response.order.token);
    } else if (input.method == "nasspay") {
      let json = JSON.parse(response.order.token);
      let transactionId = json.transactionId;
      let token = json.token;
      // let user = '7735313620'
      let user = "7517924925";

      window.location.replace(
        `https://checkout.nasswallet.com/payment-gateway?id=${transactionId}&token=${token}&userIdentifier=${user}`,
        "_blank"
      );
      // window.open(`https://uatcheckout1.nasswallet.com/payment-gateway?id=${transactionId}&token=${token}&userIdentifier=${user}`, '_blank')
    } else if (payload.method === "fib") {
      // Store the fibToken in the state
      context.commit("setFibToken", response.order);

      router.push({ name: "fibPaymentDetails" });
      // window.location.replace(response.order.token);
    }
  },
};
