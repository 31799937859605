import gql from "graphql-tag";
import { http } from "../../../graphql";
import router from "../../../router";

export default {
  refills: async (context) => {
    let query = gql`
      query refills {
        refills {
          id
          total
          status
          date
          wick {
            id
            title
            image
          }
          container {
            id
            title
          }
          color {
            id
            title
            color
            refill
          }
          size {
            id
            title
          }
          scent {
            id
            title
            image
          }
          city {
            id
            name
            price
          }
          sellingPoint {
            id
            title
          }
          method
        }
      }
    `;
    const response = await http(true, query, true);
    context.commit("setRefills", response.refills);
  },
  refill: async (context, payload) => {
    let query = gql`
      query refill(id: ${payload}) {
        refills {
          id
          total
          status
          date
          wick {
            id
            title
            image
          }
          container {
            id
            title
          }
          color {
            id
            title
            color
            refill
          }
          size {
            id
            title
          }
          scent {
            id
            title
            image
          }
          city {
            id
            name
            price
          }
          sellingPoint {
            id
            title
          }
          method
        }
      }
    `;
    const response = await http(true, query, false);
    context.commit("setRefills", response.refills);
  },
  request: async (context, payload) => {
    let mutation = gql`
      mutation refillRequest($input: RefillInput!) {
        order: refillRequest(input: $input) {
          id
          token
          fibToken {
            paymentId
            readableCode
            qrCode
            validUntil
            personalAppLink
            businessAppLink
            corporateAppLink
          }
        }
      }
    `;
    let input = {
      // lala_container: payload.container ?? false,
      container: payload.container,
      wick: payload.wick,
      size: payload.size,
      color: payload.color,
      scent: payload.scent,
      notes: payload.notes,
      method: payload.method ?? "cash",
    };

    if (payload.address) input.address = payload.address;
    if (payload.city) input.city = payload.city;
    if (payload.sellingPoint) input.selling_point = payload.sellingPoint;
    
    let response = await http(false, mutation, true, { input: input });

    if (payload.method === "zaincash") {
      window.location.replace(
        `https://api.zaincash.iq/transaction/pay?id=${response.order.token}`
      );
    } else if (payload.method == "fastpay") {
      window.location.replace(response.order.token);
    } else if (payload.method === "nasspay") {
      let json = JSON.parse(response.order.token);
      let transactionId = json.transactionId;
      let token = json.token;
      // let user = '7735313620'
      let user = "7517924925";

      window.location.replace(
        `https://checkout.nasswallet.com/payment-gateway?id=${transactionId}&token=${token}&userIdentifier=${user}`,
        "_blank"
      );
      // window.open(`https://uatcheckout1.nasswallet.com/payment-gateway?id=${transactionId}&token=${token}&userIdentifier=${user}`, '_blank')
    } else if (payload.method === "fib") {
      // Store the fibToken in the state
      context.commit("setFibToken", response.order);

      router.push({ name: "fibPaymentDetails" });
      // window.location.replace(response.order.token);
    }
  },
  guestRequest: async (context, payload) => {
    let mutation = gql`
      mutation refillRequestGuest($input: RefillGuestInput!) {
        order: refillRequestGuest(input: $input) {
          id
          token
          fibToken {
            paymentId
            readableCode
            qrCode
            validUntil
            personalAppLink
            businessAppLink
            corporateAppLink
          }
          uuid
          email
        }
      }
    `;
    let input = {
      // lala_container: payload.container ?? false,
      container: payload.container,
      wick: payload.wick,
      size: payload.size,
      color: payload.color,
      scent: payload.scent,
      method: payload.method ?? "cash",
      token: payload.token,
      guest: {
        name: payload?.guest?.name,
        email: payload?.guest?.email,
        phone: payload?.guest?.phone,
        address: payload?.guest?.address,
      },
    };

    if (payload.address) input.address = payload.address;
    if (payload.city) input.city = payload.city;
    if (payload.sellingPoint) input.selling_point = payload.sellingPoint;

    let response = await http(false, mutation, true, { input: input });

    if (payload.method === "zaincash") {
      window.location.replace(
        `https://api.zaincash.iq/transaction/pay?id=${response.order.token}`
      );
    } else if (payload.method == "fastpay") {
      window.location.replace(response.order.token);
    } else if (payload.method === "nasspay") {
      let json = JSON.parse(response.order.token);
      let transactionId = json.transactionId;
      let token = json.token;
      // let user = '7735313620'
      let user = "7517924925";

      window.location.replace(
        `https://checkout.nasswallet.com/payment-gateway?id=${transactionId}&token=${token}&userIdentifier=${user}`,
        "_blank"
      );
      // window.open(`https://uatcheckout1.nasswallet.com/payment-gateway?id=${transactionId}&token=${token}&userIdentifier=${user}`, '_blank')
    } else if (payload.method === "fib") {
      // Store the fibToken in the state
      context.commit("setFibToken", response.order);

      router.push({ name: "fibPaymentDetails" });
      // window.location.replace(response.order.token);
    }
  },
  add: async (context, payload) => {
    let mutation = gql`
      mutation addRefillCart($input: RefillCartInput!) {
        cart: addRefillCart(input: $input){
          ${cartSchema}
        }
      }
    `;
    const response = await http(false, mutation, true, {
      input: {
        quantity: payload.quantity,
        color: payload.color,
        scent: payload.scent,
        size: payload.size,
        wick: payload.wick,
        container: payload.container,
      },
    });
    context.commit("setCart", response.cart);
  },
  addLocal: (context, payload) => {
    let localCart = localStorage.getItem("refill-cart");

    let cart = {
      items: [],
    };

    if (
      localCart &&
      localCart != "" &&
      localCart != "null" &&
      localCart != "undefined"
    )
      cart = JSON.parse(localCart);

    let item = cart.items.find(
      (item) =>
        item.color.id == payload.color.id &&
        item.scent.id == payload.scent.id &&
        item.size.id == payload.size.id &&
        item.wick.id == payload.wick.id &&
        item.container.id == payload.container.id
    );

    if (item) {
      let itemIndex = cart.items.findIndex((x) => x == item);
      context.dispatch("editLocal", { ...payload, index: itemIndex });
    } else {
      let total =
        payload.quantity *
        ((payload.size.value / 100) * parseFloat(payload.color.refill) +
          parseFloat(payload.wick.price));
      let input = {
        quantity: payload.quantity,
        color: payload.color,
        scent: payload.scent,
        size: payload.size,
        wick: payload.wick,
        container: payload.container,
        total: total,
      };

      cart.items.push(input);

      localStorage.setItem("refill-cart", JSON.stringify(cart));
      context.commit("setLocal", cart);
    }
  },
  edit: async (context, payload) => {
    let mutation = gql`
      mutation editRefillCart($input: RefillCartEditInput!){
        cart: editRefillCart(input: $input){
          ${cartSchema}
        }
      } 
    `;

    let input = {
      id: payload.id,
      quantity: payload.quantity,
    };

    if (payload.color) input.color = payload.color;
    if (payload.scent) input.scent = payload.scent;
    if (payload.size) input.size = payload.size;
    if (payload.wick) input.wick = payload.wick;
    if (payload.container) input.container = payload.container;

    const response = await http(false, mutation, true, { input: input });
    context.commit("setCart", response.cart);
  },
  editLocal: (context, payload) => {
    let cart = JSON.parse(localStorage.getItem("refill-cart"));

    let item = cart.items[payload.index];

    let total = item.total;

    if (payload?.color?.id && item.color.id != payload?.color?.id) {
      item.color = payload.color;
      total =
        parseInt(item.quantity) *
        ((item.size.value / 100) * parseFloat(item.color.refill) +
          parseFloat(item.wick.price));
    }

    if (payload?.size?.id && item.size.id != payload?.size?.id) {
      item.size = payload.size;
      total =
        parseInt(item.quantity) *
        ((item.size.value / 100) * parseFloat(item.color.refill) +
          parseFloat(item.wick.price));
    }

    if (payload?.wick?.id && item.wick.id != payload?.wick?.id) {
      item.wick = payload.wick;
      total =
        parseInt(item.quantity) *
        ((item.size.value / 100) * parseFloat(item.color.refill) +
          parseFloat(item.wick.price));
    }

    if (item.quantity != payload?.quantity) {
      item.quantity = payload.quantity;
      total =
        parseInt(item.quantity) *
        ((item.size.value / 100) * parseFloat(item.color.refill) +
          parseFloat(item.wick.price));
    }

    item.total = total;
    cart.items[payload.index] = item;

    localStorage.setItem("refill-cart", JSON.stringify(cart));
    context.commit("setLocal", cart);
  },
  remove: async (context, payload) => {
    let mutation = gql`
      mutation removeRefillCart($id: ID!){
        cart: removeRefillCart(id: $id){
          ${cartSchema}
        }
      } 
    `;
    const response = await http(false, mutation, true, { id: payload });
    context.commit("setCart", response.cart);
  },
  removeLocal: (context, payload) => {
    let cart = JSON.parse(localStorage.getItem("refill-cart"));

    if (cart.items.length <= payload) return;

    cart.items.splice(payload, 1);

    localStorage.setItem("refill-cart", JSON.stringify(cart));
    context.commit("setLocal", cart);
  },
  loadLocal: (context) => {
    let localCart = localStorage.getItem("refill-cart");

    let cart = {
      items: [],
    };

    if (
      localCart &&
      localCart != "" &&
      localCart != "null" &&
      localCart != "undefined"
    )
      cart = JSON.parse(localCart);

    context.commit("setLocal", cart);
  },
  saveLocalToDB: async (context) => {
    let localCart = localStorage.getItem("refill-cart");

    if (
      localCart &&
      localCart != "" &&
      localCart != "null" &&
      localCart != "undefined"
    ) {
      let cart = JSON.parse(localCart);
      for (let i = 0; i < cart.items.length; i++) {
        let mutation = gql`
          mutation addRefillCart ($input: RefillCartInput!){
            cart: addRefillCart (input: $input){
              ${cartSchema}
            }
          }
        `;

        const response = await http(false, mutation, true, {
          input: {
            quantity: cart.items[i].quantity,
            color: cart.items[i].color.id,
            scent: cart.items[i].scent.id,
            size: cart.items[i].size.id,
            wick: cart.items[i].wick.id,
            container: cart.items[i].container.id,
          },
        });
        context.commit("setCart", response.cart);
      }
    }

    localStorage.removeItem("refill-cart");
  },
};

const cartSchema = `
  id
  quantity
  wick {
    id
    title
    image
  }
  container {
    id
    title
  }
  color {
    id
    title
    color
    refill
  }
  size {
    id
    title
  }
  scent {
    id
    title
    image
  }
  total
`;
