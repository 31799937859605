<template>
  <div class="order-summery-card">
    <div>
      <div class="text-center text-bluePrimary pt-6 lora-bold">
        {{ content.orderSummery }}
      </div>

      <div class="grid grid-cols-3 px-8 xl:px-10 mt-12">
        <div class="col-span-1 lora-bold">{{ content.items }}</div>
        <div class="col-span-1 lora-bold text-center">{{ content.qty }}</div>
        <div class="col-span-1 lora-bold text-align-end">
          {{ format(content.price) }}
        </div>
      </div>
      <div
        class="grid grid-cols-3 px-8 xl:px-10 mt-4"
        v-for="(item, index) in cart"
        :key="index"
      >
        <div class="col-span-1">{{ item.product.details.title }}</div>
        <div class="col-span-1 text-center">{{ format(item.quantity) }}</div>
        <div class="col-span-1 text-align-end">
          {{ format(total(index)) }}
          {{ content.iqd }}
        </div>
      </div>
      <div
        class="grid grid-cols-3 px-8 xl:px-10 mt-4"
        v-for="(item, index) in refillCart"
        :key="index"
      >
        <div class="col-span-1">
          {{ content.refillRequest }}
          <br />
          <p class="text-sm mt-1 opacity-70">
            {{ item.container.title }} | {{ item.size.title }} | {{ item.wick.title }} |
            {{ item.color.title }} | {{ item.scent.title }}
          </p>
        </div>
        <div class="col-span-1 text-center">{{ format(item.quantity) }}</div>
        <div class="col-span-1 text-align-end">
          {{ format(item.total) }} {{ content.iqd }}
        </div>
      </div>
      <hr style="color: #37328b22" class="my-5 mx-10" />
      <div class="grid grid-cols-5 px-8 xl:px-10 mt-4">
        <div class="col-span-1"></div>
      </div>

      <div class="flex justify-between px-8 xl:px-10 pb-12 text-size-20">
        <div class="ms-16 whitespace-nowrap">{{ content.total }}:</div>
        <div class="text-align-end whitespace-nowrap">
          {{ format(totalPrice) }} {{ content.iqd }}
        </div>
      </div>
      <div class="mx-3 my-4 py-4">
        <router-link to="/checkout">
          <button
            class="alert-btn text-text-white text-white lora-bold whitespace-nowrap bg-bluePrimary duration-300 w-full hover:text-bluePrimary hover:bg-blueSecondary"
          >
            {{ content.proceedToCheckout }}
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "../../../assets/numberFormat";

export default {
  inject: ["content"],
  props: ["cart", "refillCart"],
  computed: {
    totalPrice() {
      let total = 0;
      for (let i = 0; i < this.cart.length; i++) {
        if (this.cart[i].size) {
          total += this.cart[i].size.price * this.cart[i].quantity;
        } else {
          total += this.cart[i].product.details.price * this.cart[i].quantity;
        }
      }
      for (let i = 0; i < this.refillCart.length; i++) {
        // console.log(this.refillCart[i].total);
        total += parseFloat(this.refillCart[i].total);
      }
      return total;
    },
  },
  methods: {
    total(index) {
      return format(this.cart[index].total);
    },
    format(number) {
      return format(number);
    },
  },
};
</script>
